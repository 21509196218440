import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class MapHeaderService {


	public radius = [
		{ id: 1, radius: 5000 },
		{ id: 2, radius: 10000 },
		{ id: 3, radius: 25000 },
		{ id: 4, radius: 50000 },
	];

	public radiusSubject = new BehaviorSubject<number>(5000);

	public isOpen = new BehaviorSubject<boolean>(false);

	public zoomStatus = new Subject<boolean>();

	constructor() { }

	public setRadius(radius: number): void {
		this.radiusSubject.next(radius);
	}

	public visibleMap(status: boolean): void {
		this.isOpen.next(status);
	}

}
