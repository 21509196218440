import { Injectable } from '@angular/core';
import type { UserData } from '../interfaces/localStorage.d';
import { User } from '../interfaces/enums';

@Injectable({
	providedIn: 'root',
})
export class LocalStorageService {
	public token = '';

	public setDataToStorage<T>(key: string, data: T): void {
		localStorage.setItem(key, JSON.stringify(data));
	}

	public setToken(token: string): void {
		this.token = token;
	}

	public getItemLocalStorage<T>(key: string): T | undefined {
		try {

			const result = localStorage.getItem(key);

			if (result === null) {
				return undefined;
			}

			return JSON.parse(result);
		} catch (error) {
			return undefined;
		}
	}

	public async getUserId(): Promise<number> {
		try {
			const result = await this.getItemLocalStorage<UserData>(
				User.USER_DATA
			);
			return result!.id;
		} catch (error) {
			throw new Error(`Not found userId in storage ${User.USER_DATA}`);
		}
	}

	public getUserToken(): string | undefined {
		try {
			const storageToken = localStorage.getItem(User.USER_DATA);

			if (storageToken === null) {
				return undefined;
			}

			return JSON.parse(storageToken).authToken as string;
		} catch (error) {
			throw new Error(`${error}`);
		}
	}

	public removeItem(key: string): void {
		localStorage.removeItem(key);
	}

	public clear(): void {
		localStorage.clear();
	}
}
