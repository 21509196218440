import {
	Component,
	OnInit,
} from '@angular/core';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.style.scss'],
})
export class FooterComponent implements OnInit {

	public visible: boolean = false;
	ngOnInit(): void {
	}

	public openModal() {
		this.visible = true;
	}
}
