import { EventsService } from '../../../core/services/api/events/events.service';
import { EventsHashService } from '../../../core/services/api/events/EventsHash.service';
import {
	Component,
	OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';




@Component({
	selector: 'app-event-detail',
	templateUrl: './event-detail.component.html',
	styleUrls: [
		'./event-detail.component.scss',
	],
})
export class EventDetailComponent implements OnInit {

	constructor(
		private eventService: EventsService,
		private router:Router,
		private eventHashService:EventsHashService

	) {}

	public ngOnInit(): void {}

	public navigateToEdit(event:any): void {
		console.log(event)
		const formattedTimestamp = moment(event.timestamp).unix();
		const code = this.eventHashService.encodeEventHash(
			event.id,
			formattedTimestamp
		);
		this.router.navigate([`/event/edit/${code}`]);
	}
}
