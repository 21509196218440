<div class="map-container">
	<div class="map-frame">
		<div id="map">
			<button class="locate-button" (click)="locate()">
				<svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd"
						d="M2.385 12.42l16.01-7.614a.6.6 0 0 1 .8.8l-7.616 16.009a.6.6 0 0 1-1.11-.068l-2.005-6.012-6.01-2.003a.6.6 0 0 1-.069-1.111z"
						fill="currentColor"></path>
				</svg>
			</button>
		</div>
	</div>
</div>
