import { Component } from '@angular/core';
import { Actions } from 'src/app/core/interfaces/websocket/websocket.interfaces';
import { EventProfileComponent } from '../shared/event-profile';

@Component({
	selector: 'app-tab-events',
	templateUrl: './tab-events.component.html',
	standalone: true,
	imports: [EventProfileComponent]
})
export class TabEventsComponent {
	public readonly wsConnectType: Actions = 'user_events';
}
