@if (isLoading) {
<div style="width: 100%;display: flex;justify-content: center;margin-top: 50px;">
	<p-progress-spinner ariaLabel="loading" />
</div>

} @if(!isLoading) {
@if(userInfo.length > 0){
<div class="friends-container">
	<article *ngFor="let item of userInfo" class="friends-container__card" (click)="navigateToDetail(item.user_id)">
		<div class="friends-container__card-link">
			<img src="../../../../../assets/images/profile.png" alt="">
			<p>
				<a (click)="navigateToDetail(item.user_id)">{{ item.name }}</a>
				<br>
				@if (item.can_change && !item.approve) {
					<span>В ожидании ответа</span>
				} @else if (!item.can_change && !item.approve) {
					<span>Заявка отправлена</span>
				} @else {
					<span>В друзьях</span>
				}
			</p>

		</div>
	</article>
</div>
}@else {<div class="friends-container">
	<h3>У вас пока нет друзей</h3>
</div>}
}
