import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";

@Pipe({ name: 'formattedDate' })
export class FormattedDatePipe implements PipeTransform {
	transform(date: string, format = 'DD.MM.YYYY'): string {
		if (!date) {
			return 'Укажите дату'; 
		}

		return moment(date).format(format);
	}
}