<header>
	<div class="nav">
		<div class="nav__logo" (click)="navigateToHome()">
			<img src="../../../../assets/images/porebric.png" alt="Logo" />
		</div>
		<div class="nav__right-side" [ngClass]="{ 'dropdown-active': isDropdownVisible }">
			@if (isAuth) {
				<p-button routerLink="/event/create">Создать событие</p-button>
			} @else {
				<p-button (click)="showDialog()">Создать событие</p-button>
			}

			@if (isAuth) {
				<p-button
					class="nav__profile-icon"
					icon="pi pi-user"
					[rounded]="true"
					severity="secondary"
					[outlined]="true"
					(click)="toggleDropdown()">
				</p-button>
				<div class="dropdown-menu">
					<ul>
						<li><a [routerLink]="['/profile']">Профиль</a></li>
						<li><a [routerLink]="['/profile/tab-friends']">Друзья</a></li>
						<li><a [routerLink]="['/profile/tab-subscribe']">Подписки</a></li>
						<li><a (click)="close()">Выход</a></li>
					</ul>
				</div>
			} @else {
				<p-button
					class="nav__profile-icon"
					icon="pi pi-sign-in"
					[rounded]="true"
					severity="secondary"
					[outlined]="true"
					(click)="showDialog()">
				</p-button>
			}
		</div>
	</div>
</header>
<p-dialog header="Форма входа" [modal]="true" [(visible)]="visible" [style]="{ width: '25rem'}">
	<app-sign-in></app-sign-in>
</p-dialog>
