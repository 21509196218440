<div class="card__container">
	@if(isLoading){
		<div style="width: 100%;display: flex;justify-content: center;margin-top: 50px;">
			<p-progress-spinner ariaLabel="loading" />
		</div>
	} @else {
		<div class="card-list">
			@for (item of events; track $index) {
				<app-events-item [item]="item" (onAction)="navigateToDetail(item.id, item.time_start)"></app-events-item>
			}
		</div>
		<p-paginator [totalRecords]="totalPage" [rows]="12" [pageLinkSize]="3" [alwaysShow]="false" (onPageChange)="changePage($event)"></p-paginator>
	}
</div>
