import { Component, input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import moment from "moment";
import { PaginatorModule, PaginatorState } from "primeng/paginator";
import { Subject } from "rxjs";
import { User } from "src/app/core/interfaces/enums";
import { Actions } from "src/app/core/interfaces/websocket/websocket.interfaces";
import { EventsHashService } from "src/app/core/services/api/events/EventsHash.service";
import { WebsocketService } from "src/app/core/services/websocket/websocket.service";
import { EventsProfileSubClass } from "src/app/shared/subclass/events-profile.subclass";
import { EventsItemComponent } from "../../events-item/events-item";
import {ProgressSpinnerModule} from 'primeng/progressspinner';

@Component({
	selector: 'app-events-profile',
	templateUrl: './event-profile.component.html',
	standalone: true,
	imports: [EventsItemComponent, PaginatorModule,ProgressSpinnerModule],
	providers: [WebsocketService]
})

export class EventProfileComponent extends EventsProfileSubClass implements OnInit, OnDestroy {
	private destroy$ = new Subject<void>();

	public code!: string;

	public wsConnectType$ = input<Actions>();

	constructor(
		protected override wsService: WebsocketService,
		protected router: Router,
		protected eventHashService: EventsHashService,
		protected route: ActivatedRoute
	) {
		super(wsService);
	}

	ngOnInit(): void {
		if (this.route.snapshot.paramMap.get('id')) {
			this.userId = Number(this.route.snapshot.paramMap.get('id'));
		} else {
			const authToken: any = localStorage.getItem(User.USER_DATA);
			const id = JSON.parse(authToken);
			this.userId = id.id;
		}
		this.connect();
		super.onEventResponse();

		if (this.wsConnectType$() === 'sub_events') {
			this.getEvent(undefined, 'sub_events');
		} else {
			this.getEvent();
		}
	}

	public connect(): void {
		this.wsService.connect(this.wsConnectType$());
	}

	public loadMoreEvents(page: number, action?: Actions): void {
		if (this.wsConnectType$() === 'sub_events') {
			this.getEvent(page, 'sub_events');
		} else {
			this.getEvent(page);
		}
	}

	public navigateToDetail(id: number, timestamp: string): void {
		const formattedTimestamp = moment(timestamp).unix();
		const code = this.eventHashService.encodeEventHash(
			id,
			formattedTimestamp
		);
		window.open(`/event/${code}`, '_blank');
	}

	public changePage(e: PaginatorState): void {
		this.scrollToTop();
		if (typeof e.page !== 'undefined') {
			this.page = e.page;
			this.loadMoreEvents(e.page);
		}
	}

	private scrollToTop(): void {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}

	ngOnDestroy(): void {
		this.wsService.disconnect();
		this.destroy$.next();
		this.destroy$.complete();
	}
}
