import { NgModule } from '@angular/core';
import { EventIcon } from './event-icon.pipe';
import { Timer } from './time';
import { TimeOfDay } from './time-of-day.pipe';
import { CoordinatesPipe } from './coordinates.pipe';
import { FormattedDatePipe } from './formatted-date.pipe';

@NgModule({
	declarations: [Timer, EventIcon, TimeOfDay, CoordinatesPipe, FormattedDatePipe],
	exports: [Timer, EventIcon, TimeOfDay, CoordinatesPipe, FormattedDatePipe],
})
export class PipeModule {}
