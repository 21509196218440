import {
	Component,
	OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { from } from 'rxjs';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.style.scss'],
})
export class HeaderComponent implements OnInit {

	public isAuth: any | null = null;

	public visible = false;

	public isDropdownVisible = false;

	constructor(
		protected router: Router,
	) { }

	public async ngOnInit(): Promise<void> {
		this.getItemFromLocalStorage('user_data').subscribe({
			next: (userData): any => {
				this.isAuth = userData;
			},
		});
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	public getItemFromLocalStorage(key: string) {
		const item = localStorage.getItem(key);
		return from([item]);
	}

	public showDialog(): void {
		this.visible = true;
	}

	public toggleDropdown(): void {
		this.isDropdownVisible = !this.isDropdownVisible;
	}

	public navigateToHome(): void {
		this.router.navigate(['']);
	}

	public close() {
		localStorage.clear();
		window.location.reload();
	}
}
