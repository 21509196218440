<div class="search-filters">
	<div class="search-filters--form" >
		<p-datePicker [touchUI]="isTouchUI"  placeholder="Период поиска" name="event-date-time" [(ngModel)]="selectDate$" [showIcon]="true" [iconDisplay]="'input'" dateFormat="dd.mm.yy" selectionMode="range">
			<ng-template pTemplate="footer">
				<p-button class="p-datepicker-prev hidden">Previous</p-button>
				<p-button class="p-datepicker-next hidden">Next</p-button>
			</ng-template>
		</p-datePicker>
		<p-iconField iconPosition="right">
			<p-inputIcon styleClass="pi pi-map"></p-inputIcon>
			<p-autocomplete
				[(ngModel)]="defaultAdress"
				(ngModelChange)="onAddressChange($event)"
				(completeMethod)="searchByAddress($event)"
				[suggestions]="selectName"
				(onSelect)="selectAdress($event)"
				[showEmptyMessage]="true"
				[showClear]="true"
				[panelStyle]="{ 'max-width': '100%' }"
				[inputStyle]="{ 'border-radius':'8px' }"
				[fluid]="true"
				placeholder="Адрес"
				>
			</p-autocomplete>
			<small (click)="visibleMap()" >Показать на карте</small>
		</p-iconField>
		<div class="search-filters-btn">
			<p-button class="search-filters-btn__settings-icon" icon="pi pi-sliders-h" severity="secondary" (click)="showDialogSettings()"></p-button>
			<p-button class="search-filters-btn__settings-btn" severity="secondary" (click)="showDialogSettings()">Доп настройки</p-button>
			<p-button type="submit" (click)="submitSearchEvent()" [disabled]="!safeSelectDate.length || !selectName.length">Найти</p-button>
		</div>
	</div>
	<br>
	<div>
		@if (defaultAdress) {
			<p><b>Место</b>: {{ defaultAdress }}</p>
		  } @else {
			<p><b>Место</b>: Укажите адрес, где искать</p>
		  }
		  <p><b>Дата</b>:
			{{ safeSelectDate.length === 2 && !safeSelectDate[1] ?
			  (safeSelectDate[0] | formattedDate) :
			  (safeSelectDate.length === 2 && safeSelectDate[1]) ?
				(safeSelectDate[0] | formattedDate) : 'Укажите дату'
			}}
		  </p>
</div>
<p-dialog header="Доп настройки" [modal]="true" [(visible)]="openSettings" [style]="{ width: '25rem'}">
	<h3 class="edit-card-checkbox">Радиус</h3>
	<div class="card flex justify-center">
		<div>
			<div class="flex items-center" style="padding: 5px 0;">
				<p-radiobutton name="5" value="5000" [(ngModel)]="radius" inputId="radius1" (ngModelChange)="mapHeaderService.setRadius(radius)" />
				<label for="radius1" class="ml-2" style="margin-left: 10px;">5 км</label>
			</div>

			<div class="flex items-center" style="padding: 5px 0;">
				<p-radiobutton name="10" value="10000" [(ngModel)]="radius" inputId="radius2" (ngModelChange)="mapHeaderService.setRadius(radius)" />
				<label for="radius2" class="ml-2" style="margin-left: 10px;">10 км</label>
			</div>

			<div class="flex items-center" style="padding: 5px 0;">
				<p-radiobutton name="25" value="25000" [(ngModel)]="radius" inputId="radius3" (ngModelChange)="mapHeaderService.setRadius(radius)" />
				<label for="radius3" class="ml-2" style="margin-left: 10px;">25 км</label>
			</div>

			<div class="flex items-center" style="padding: 5px 0;">
				<p-radiobutton name="50" value="50000" [(ngModel)]="radius" inputId="radius4" (ngModelChange)="mapHeaderService.setRadius(radius)" />
				<label for="radius4" class="ml-2" style="margin-left: 10px;">50 км</label>
			</div>
		</div>
	</div>
</p-dialog>
