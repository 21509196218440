import { map, Observable, BehaviorSubject, firstValueFrom, lastValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { SuccessReturn } from '../../../interfaces/api';
import {
	DeleteEvent,
	Occasion,
	Occasions,
	ParamsAddEventRes,
	SearchParamsEventBySubscription,
} from '../../../interfaces/events';
import { Api } from '../api.service';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from '../../localStorage.service';
import { IUserStorageData } from '../../../interfaces/users';
import { User } from '../../../interfaces/enums';
import { TokenService } from '../user/token.service';

@Injectable({
	providedIn: 'root',
})
export class EventsService {
	public events = new BehaviorSubject(Object.create(null));

	constructor(private apiService: Api, private http: HttpClient, private tokenService: TokenService) {}

	public deleteEvent(
		paramsDeleteEvent: DeleteEvent
	): Observable<SuccessReturn> {
		return this.apiService.sendDeleteRequest<DeleteEvent, SuccessReturn>(
			'event',
			paramsDeleteEvent
		).pipe(map((response) => response));
	}

	/**
	 * @deprecated
	 * @param paramsAddEvent
	 * @param method
	 */
	public addOrUpdateEvent(
		// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
		paramsAddEvent: any,
		method: 'put' | 'post'
	): Observable<Occasion> {
		const url = 'event';
		return this.apiService.sendRequest<ParamsAddEventRes, Occasion>(
			url,
			method,
			paramsAddEvent
		).pipe(map((occasion) => occasion));
	}

	public searchById(id: number): void {
		this.apiService.sendGetRequest<null, Occasions>(`events/user?val=${id}`).subscribe(
			(occasion) => {
				this.events.next(occasion);
			}
		);
	}

	public searchBySubscription(
		searchParams: SearchParamsEventBySubscription
	): Observable<Occasions> {
		const { val, userId, limit, sort } = searchParams;
		const url = `events/subscribe?sort=${sort}&limit=${limit}&user_id=${userId}&val=${val}`;
		return this.apiService.sendGetRequest<null, Occasions>(url).pipe(
			map((occasion) => occasion)
		);
	}

	public getEvent(id: number, timestamp: number): Observable<any> {
		const url = `event?id=${id}&time_start=${timestamp}`;
		return this.apiService.sendGetRequest<null, Occasions>(url).pipe(
			map((occasion) => occasion)
		);
	}

	public subscriptionEvent(id: number, solution: boolean): Observable<any> {
		const url = `subscribe/suggest`;
		const body = {
			event_id: id,
			subscribe: solution,
		};
		return this.apiService.sendPostRequest<any, any>(url, body);
	}

	public approveSubscriptionEvent(
		id: number,
		solution: boolean,
		user: number
	): Observable<any> {
		const url = `subscribe/confirm`;
		const body = {
			event_id: id,
			confirm: solution,
			subscriber: user,
		};
		return this.apiService.sendPostRequest<any, any>(url, body);
	}

	public  getAddress(lat: number, lng: number): Observable<any> {
		const url = `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`;
		return this.http.get(url);
		}
}
