import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

export interface CoordinateInterface {
	addresstype: string
	boundingbox: string[]
	class: string
	display_name: string
	importance: number
	lat: any
	licence: string
	lon: any
	name: string
	osm_id: number
	osm_type: string
	place_id: number
	place_rank: number
	type: string

}
interface Address {
	road: string;
	village: string;
	municipality: string;
	county: string;
	state: string;
	ISO3166_2_lvl4: string;
	region: string;
	country: string;
	country_code: string;
}

interface Location {
	place_id: number;
	licence: string;
	osm_type: string;
	osm_id: number;
	lat: number;
	lon: number;
	class: string;
	type: string;
	place_rank: number;
	importance: number;
	addresstype: string;
	name: string;
	display_name: string;
	address: Address;
	boundingbox: [number, number, number, number];
}


@Injectable({
	providedIn: 'root'
})
export class GeoCodingService {
	public coordinates = new Subject<number[]>();

	public currentAdress = new BehaviorSubject<string>('');

	public selectCoordinate = new Subject<CoordinateInterface[]>();

	constructor(protected httpClient: HttpClient) { }

	public run(address: string): void {
		this.httpClient
			.get<CoordinateInterface[]>(`https://nominatim.openstreetmap.org/search?format=json&q=${address}`)
			.subscribe({
				next: (value) => {
					if (value.length > 0) {
						const { lat, lon } = value[0];
						this.coordinates.next([parseFloat(lat), parseFloat(lon)]);
						this.selectCoordinate.next(value.slice(0, 5));
					} else {
						this.selectCoordinate.next([]);
					}
				}
			});
	}

	public getAddressIntoCoordinate({ lat, lng }: { lat: number, lng: number }): any {
		this.httpClient
			.get<Location>(`https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`)
			.subscribe({
				next: (value) => {
					this.currentAdress.next(value.display_name);
				}
			});
	}
}
