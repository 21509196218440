<app-header></app-header>
@if(!isEditComponent() && !isCreate()) {
<div class="event-card">
	<div class="event-card-hero-block" [ngStyle]="{ 'background-image': 'url(' + typeImageService.curentTypeImage.value.url + ')' }"></div>

	<div class="event-card-title">
		<h4>{{ event.title }}</h4>

		@if(owner) {
			<p-button class="profile-icon_edit" icon="pi pi-pencil" severity="secondary" [outlined]="true" (click)="navigateToEditComponent()"></p-button>
			<p-button label="Редактировать" class="profile-btn_edit" severity="secondary" [outlined]="true" (click)="navigateToEditComponent()"></p-button>
		} @else {
			@switch (event.sub_level) {
				@case (0) {
					<p-button label="Подписаться" (click)="subscribeRequest(event.event_id, true)" severity="secondary" [disabled]="blockButtons" />
				}
				@case (1) {
					<p-button label="В ожидании" severity="secondary" [disabled]="true" />
				}
				@case (2) {
					<p-button label="Отписаться" (click)="subscribeRequest(event.event_id, false)" severity="secondary" [disabled]="blockButtons" />
				}
				@case (3) {
					<p-button label="Отклонено" severity="secondary" [disabled]="true" />
				}
			}
		}
	</div>
	<ul class="event-card-date-list">
		<li><i class="pi pi-calendar" style="font-size: 1.5rem"></i>  {{formatDate(event.time_start)}}</li>
		<li><i class="pi pi-clock" style="font-size: 1.5rem"></i>  {{formatTime(event.time_start)}}</li>
		<li><i class="pi pi-map-marker" style="font-size: 1.5rem"></i>  {{adressEvent}}</li>
	</ul>

	<p class="m-0">{{event.description}}</p>
	<br>
	@if(event.time_end) {
		<p>Окончание события: <span style="font-weight: bold;">{{formatDate(event.time_end)}} в {{formatTime(event.time_end)}}</span></p>
	}
	<br>
	<p class="event-card__creator">Автор: <a (click)="navigateToDetail(event.creator_id)">{{event.creator_name}}</a></p>
</div>
<div class="subscribers-container">
	<p>Подписалось {{event.sub_users.length}} человек(a)</p>
	<article *ngFor="let item of event.sub_users" class="subscribers-container__card">
		<div class="subscribers-container__card-content">
			<img src="../../../../assets/images/profile.png" alt="">
			<p (click)="navigateToDetail(item.id)">{{ item.name }}</p>
		</div>
		@if(owner) {
		<div class="subscribers-container__card-btn">
			@switch (item.sub_level) {
				@case (1) {
					<p-button icon="pi pi-check" severity="success" [outlined]="true" (click)="approveSubscribeRequest(event.event_id, true, item.id)" [disabled]="blockButtons" />
				}
				@case (2) {
					<p-button icon="pi pi-times" severity="danger" [outlined]="true" (click)="approveSubscribeRequest(event.event_id, false, item.id)" [disabled]="blockButtons" />
				}
				@case (3) {
					<p-button icon="pi pi-check" severity="success" [outlined]="true" (click)="approveSubscribeRequest(event.event_id, true, item.id)" [disabled]="blockButtons" />
				}
			}
		</div>
		}
	</article>

</div>
} @else {
<form class="event-card" [formGroup]="form">
	<div class="event-card-hero-block"
     [ngStyle]="{
        'background-image': 'url(' + typeImageService.curentTypeImage.value.url + ')'
     }">
</div>
	<div class="edit-card-title">
		<input
		type="text"
		placeholder="Название"
		id="event_name"
		pInputText name="event-name"
		[(ngModel)]="dataFormEvent.title"
		[value]="isEditComponent() ? event.title : ''"
		formControlName="title"
		[ngClass]="{'invalid-input': isInvalid('title')}"
		/>
	</div>
	<div class="event-card-elem-error">
		<ng-container
		*ngIf="isInvalid('title')"
		[ngTemplateOutlet]="requireError">
		</ng-container>
	</div>

	<div class="edit-card-configure-block">
		<p-select
		[options]="typeImageService.types"
		name="event-type"
		(onChange)="changeTypeImage($event)"
		[(ngModel)]="typeImageService.types"
		optionLabel="name"
		/>

		<div>
		<p-datePicker
		[touchUI]="isTouchUI"
		placeholder="Дата начала"
		name="event-time_start"
		[(ngModel)]="dataFormEvent.selectedStartDate$"
		[showTime]="true"
		[showIcon]="true"
		[iconDisplay]="'input'"
		dateFormat="dd.mm.yy"
		formControlName="selectedStartDate"
		[minDate]="minDate"
		[inputStyle]="getInputStyle()"
		>
			<ng-template pTemplate="footer">
				<p-button class="p-datepicker-prev hidden">Previous</p-button>
				<p-button class="p-datepicker-next hidden">Next</p-button>
			</ng-template>
		</p-datePicker>

		<div class="event-card-elem-error">
			<ng-container
			*ngIf="isInvalid('selectedStartDate')"
			[ngTemplateOutlet]="requireError">
			</ng-container>
		</div>
		</div>
		<div class="p-autocomplete-container">
			<p-autocomplete
				[suggestions]="selectName"
				(completeMethod)="searchByAddress($event)"
				(onSelect)="selectAdress($event)"
				[showEmptyMessage]="true"
				[panelStyle]="{ 'max-width': '100%' }"
				[inputStyle]="{ 'border-radius':'8px' }"
				[fluid]="true"
				formControlName = 'defaultAdress'
				>
			</p-autocomplete>
			<div>
				<small style="cursor: pointer;" (click)="openMap(true)">Показать на карте</small>
			</div>
		</div>
	</div>
	<div>
		@if(mapHeaderService.isOpen.value){
			@if (isMobile) {
				<p-dialog
					header="Выбрать место"
					[ngClass]="{home__mobile: isMobile}"
					[modal]="true"
					[(visible)]="isOpen"
					[style]="{width: '100%', height: '100vh'}"
				>
					<div class="edit-card-map map-container" @showMap>
						<div class="map__wrapper">
							<app-map #map [visibleRadius]="false" [visibleControl]="true"></app-map>
						</div>
					</div>
				</p-dialog>
			} @else {
				<div class="edit-card-map map-container" @showMap>
					<app-map-header [withoutHeader]="false"></app-map-header>
					<div class="map__wrapper">
						<app-map #map [visibleRadius]="false" [visibleControl]="true"></app-map>
					</div>
				</div>
			}
		}
	</div>
	<div class="edit-card-checkbox">
		<p-checkbox (click)="openAndCloseModal('showEndDate')" [binary]="true" />
		<span>Время окончания события</span>
	</div>
	<div *ngIf="modals['showEndDate']" class="edit-card-configure-block">
		<p-datePicker
		[keepInvalid]="true"
		placeholder="Дата окончания"
		name="event-time_end"
		[(ngModel)]="dataFormEvent.selectedEndDate$"
		[showTime]="true"
		[showIcon]="true"
		[iconDisplay]="'input'"
		dateFormat="dd.mm.yy"
		formControlName="selectedEndDate"
		[minDate]="minDate"
		>
			<ng-template pTemplate="footer">
				<p-button class="p-datepicker-prev hidden">Previous</p-button>
				<p-button class="p-datepicker-next hidden">Next</p-button>
			</ng-template>
		</p-datePicker>
	</div>
	<textarea
		rows="10"
		pTextarea
		[autoResize]="true"
		name="event-description"
		placeholder="Описание"
		[value]="isEditComponent() ? event.description : ''" [(ngModel)]="dataFormEvent.description"
		formControlName="description"
		[ngClass]="{'invalid-input': isInvalid('description')}"
		>
    </textarea>
	<div style="margin-top: 10px;">
		<ng-container
		*ngIf="isInvalid('description')"
		[ngTemplateOutlet]="requireError">
		</ng-container>
	</div>
	<div class="edit-card-save">
		@if(isCreate()){
		<p-button (onClick)="createEvent()">Сохранить</p-button>
		}
		@if(isEditComponent()){
		<p-button class="edit-card-save_btn" (onClick)="sendEventFormData()">Сохранить</p-button>
		<p-button (onClick)="openAndCloseModal('deleteModal')">Удалить</p-button>
		}
	</div>
</form>
}

<app-footer></app-footer>

<p-dialog
	[(visible)]="modals['mapModal']"
	[modal]="true" (onShow)="openAndCloseModal('isOpenMap')"
	[breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
	[style]="{ width: 'inherit',height: 'inherit' }"
	[blockScroll]="false">
	<div>
		<div class="edit-card-map">
			<div class="map__wrapper">
				@if(modals['isOpenMap']){
				<app-map #map></app-map>
				}
			</div>
		</div>
	</div>
</p-dialog>

<!-- Modal -->
<p-dialog [(visible)]="modals['saveModal']" header="Сохранить это событие?" [modal]="true"
	[style]="{ width: '50vw',heght:'50vh' }">
	<div>
		<p>Подвердите сохранение события</p>
		<div style="display: flex;justify-content: flex-end;">
			<p-button label="Отмена" />
			<p-button label="Сохранить" severity="danger" />
		</div>
	</div>
</p-dialog>

<p-dialog [(visible)]="modals['createModal']" header="Создать это событие?" [modal]="true"
	[style]="{ width: '50vw',heght:'50vh' }">
	<div>
		<p>Подвердите создание события</p>
		<div style="display: flex;justify-content: flex-end;">
			<p-button label="Отмена" />
			<p-button label="Создать" severity="danger" />
		</div>
	</div>
</p-dialog>


<p-dialog
          [(visible)]="modals['deleteModal']"
		  header="Удалить это событие?"
		  [modal]="true"
	      [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
		  [style]="{ width: '50vw' }"
	      [blockScroll]="false">
	<div>
		<div class="delete_modal" >
			<p-button label="Отмена"/>
			<p-button label="Удалить" severity="danger" (onClick)="deleteEvent(true)" />
		</div>
	</div>
</p-dialog>

<ng-template #requireError>
    <div class="validation-error-label">
        Это поле обязательно для заполнения.
    </div>
</ng-template>
