import { signal } from '@angular/core';
import moment from 'moment';
import { Subject } from 'rxjs';
import { IWsMessage } from '../../core/interfaces/websocket/websocket.interfaces';
import { CoordinateService } from '../../core/services/coordinates/coordinate.service';
import { WebsocketService } from '../../core/services/websocket/websocket.service';
import { Events, EventTypeSearchMain } from '../../core/interfaces/events';
import { MapHeaderService } from '../components/map/map-header/map-header.service';

export class EventsMainSubClass {
	public clockAndData = {
		year: '',
		month: '',
		day: '',
		hr: '',
		min: '',
		sec: '',
	};

	public unixDate!: moment.Moment;

	public timeSelectNow!: string;

	public valTime = {
		hour: '',
		minute: '',
	};

	public dateNow = new Date();

	public position!: number[];

	public destroy$ = new Subject<void>();

	public request = signal(true);

	public page = 0;

	public typeSearch!: EventTypeSearchMain;

	public today = moment(this.dateNow).format();

	public timeNow = moment().format('HH:mm');

	public clearPage$ = new Subject<void>();

	constructor(
		protected wsService: WebsocketService,
		protected coordinateService: CoordinateService,
		private headerService: MapHeaderService
	) {
	}

	public getEvents(type: EventTypeSearchMain, date: moment.Moment, time: number, page?: number): void {
		this.request.set(true);
		this.page = page ?? 0;

		const [lat, lon] = this.coordinateService.coordinates$.value;

		const unixDate = date.unix();

		this.sendEventRequest(
			{
				date: unixDate,
				time,
				coordinateLat: lat,
				coordinateIon: lon,
			},
			type
		);

		this.request.set(false);
	}


	private sendEventRequest(
		params: { date: number; time: number; coordinateLat: number; coordinateIon: number },
		type: EventTypeSearchMain
	) {
		const { date, time, coordinateLat, coordinateIon } = params;
		this.typeSearch = type;

		if (type === 'recommendation') {
			this.wsService.send<IWsMessage<Events.Search>>({
				action: 'general_events',
				body: {
					min: time,
					time_start: date,
					lat: coordinateLat,
					lon: coordinateIon,
					radius: 0, // If there is no radius, this indicates recommendations
					page: this.page,
				}
			});
			return;
		}

		// 1725190200

		// mock data
		// "min": 1440,
		// "time_start": 1725158520,
		// "lat": 55.74514366581146,
		// "lon": 37.6249122619629,
		// "radius": 25000,
		// "page": 0

		this.wsService.send<IWsMessage<Events.Search>>({
			action: 'general_events',
			body: {
				min: time,
				time_start: date, // 1725158520
				lat: coordinateLat,
				lon: coordinateIon,
				radius: Number(this.headerService.radiusSubject.value),
				page: this.page,
			}
		});
	}
}
