
<footer>
	<div class="footer-info">
		<div class="footer-logo">
			<img src="assets/images/porebric-nobg.png" alt="Logo"/>
		</div>
		<div >
			<div class="footer-contacts">
				<div> Telegram-канал </div>
				<div> <a href="https://t.me/public_porebric"><i class="pi pi-telegram" style="font-size: 1.5rem"></i></a></div>
			</div>
			<div class="footer-contacts">
				<div> Вопросы и предложения </div>
				<div> <a href="https://t.me/RealGunStar"><i class="pi pi-telegram" style="font-size: 1.5rem"></i></a></div>
			</div>
			<div class="footer-politics">
				<div class="footer-info__link"><a (click)="openModal()">Политика конфиденциальности</a></div>
			</div>
		</div>
	</div>
</footer>

<p-dialog
	header="Политика конфиденциальности"
	class="footer-info__link"
	[modal]="true"
	[(visible)]="visible"
	[style]="{ width: '25rem'}">
	<p class="mb-8">
		Наша Политика конфиденциальности описывает, как мы собираем, используем и защищаем вашу информацию.
	</p>
	<br>
	<p class="mb-8">
		Мы собираем только ту информацию, которая необходима для предоставления качественных услуг, таких как:
	</p>
	<ul class="mb-8">
		<li>Персональные данные (имя, адрес электронной почты, номер телефона).</li>
		<li>Информация об активности на платформе (посещения, взаимодействия).</li>
		<li>Техническая информация (IP-адрес, данные браузера).</li>
	</ul>
	<br>
	<p class="mb-8">
		Мы гарантируем защиту ваших данных и принимаем меры для предотвращения несанкционированного доступа.
	</p>
	<br>
	<p class="mb-8">
		Ваши данные используются для:
	</p>
	<ul class="mb-8">
		<li>Предоставления и улучшения наших услуг.</li>
		<li>Обеспечения безопасности и предотвращения нарушений.</li>
		<li>Разработки новых функций и возможностей.</li>
	</ul>
	<br>
	<p class="mb-8">
		Для получения дополнительной информации свяжитесь с нами через <a href="https://t.me/RealGunStar">наш телеграм-канал</a>.
	</p>
</p-dialog>
